.ErrorImageOverlay {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  margin: 50px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorImageContainer {
  display: inline-block;
  background-image: url('https://i.imgur.com/lKJiT77.png');
  background-size: cover;
  background-position: center;
  width: 40vh;
  height: 40vh;
}

.ErrorImageText {
  font-size: 30px;
  color: black;
  margin: 0;
}

.ErrorImageSmallText {
  font-size: 19px;
  font-weight: 300;
  text-align: center;
  color: black;
}
