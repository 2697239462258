.rmdbGrid h1 {
  font-family: 'Abel', sans-serif;
  font-size: 42px;
}

.rmdbGridContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  position: relative;
}

.rmdbGridContentNull {
  p {
    font-family: 'Abel', sans-serif;
    font-size: 28px;
  }
}

.rmdbGridElement {
  animation: animateGrid 0.5s;
}

@keyframes animateGrid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .rmdbGridContent {
    grid-template-columns: repeat(2, 1fr);
  }

  .rmdbGrid h1 {
    font-size: 28px;
  }

  .rmdbGridContentNull {
    p {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 375px) {
  .rmdbGridContent {
    grid-template-columns: repeat(1, 1fr);
  }
}
