.rmdbMoviethumb img {
  width: 100%;
  height: auto;
  transition: all 0.3s;
  object-fit: cover;
  border-radius: 20px;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
