.rmdbActor {
  height: auto;
  min-height: 30px;
  background: #1c1c1c;
  border-radius: 20px;
  padding: 25px;
  text-align: center;

  img {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto;
  }
}

.rmdbActorName {
  display: block;
  font-family: 'Abel', sans-serif;
  font-size: 22px;
  color: #fff;
  box-sizing: border-box;
  margin: 20px 0 0 0;
}

.rmdbActorCharacter {
  display: block;
  font-family: 'Abel', sans-serif;
  font-size: 18px;
  color: #fff;

  box-sizing: border-box;
}

a {
  text-decoration: none;
}
