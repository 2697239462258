.rmdbHeader {
  width: 100%;
  height: auto;
  background: #1c1c1c;
  padding: 0 20px;
  box-sizing: border-box;
}

.rmdbHeaderContent {
  max-width: 1280px;
  min-height: 120px;
  height: auto;
  padding: 20px 0px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}

.rmdbLogo {
  width: 300px;
  margin-top: 20px;
  float: left;
}

.rmdbTmdbLogo {
  width: 122px;
  margin-top: 25px;
  float: right;
}

@media screen and (max-width: 500px) {
  .rmdbHeaderContent {
    max-width: 1280px;
    min-height: 0px;
  }

  .rmdbTmdbLogo {
    display: inline-block;
    width: 80px;
    margin-top: 0px;
  }

  .rmdbLogo {
    width: 150px;
    margin-top: 5px;
  }
}
